import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from './Modal';
import { MdOpenInNew } from "react-icons/md";

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired
}

const defaultProps = {
  children: null,
  title: ''
}

class TimelineItem extends React.Component {

  state = {
    modalOpen: false,
  };

  openModal = () => this.setState({modalOpen:true})
  closeModal = () => this.setState({modalOpen:false})

  render() {
    const {
      className,
      children,
      title,
      link,
      date,
      modalContent,
      ...props
    } = this.props;

    const classes = classNames(
      'timeline-item',
      className
    );

    return (
      <>
      <div
        {...props}
        className={classes}>
        <div className="timeline-item-inner clickable" onClick={this.openModal}>
          <div className="timeline-item-header tt-u mb-4 reveal-fade">{date}</div>
          <div className="timeline-item-content fw-700 h4 m-0 reveal-from-side">
            {title} <MdOpenInNew style={{display:"inline"}}/>
          </div>
          <div className="timeline-item-content m-0 reveal-from-side">
            {children[0]}
          </div>
        </div>
      </div>

      <Modal
          id="demo-modal"
          show={this.state.modalOpen}
          handleClose={this.closeModal}
        >
          <div className="">
            <h3 className="mt-16 mb-8">{title}</h3>
            <p className="text-sm">{children[1]}</p>
          </div>
        </Modal>
      </>
    );
  }
}

TimelineItem.propTypes = propTypes;
TimelineItem.defaultProps = defaultProps;

export default TimelineItem;