import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader title="Through-seats for Split Tickets" className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Split Tickets
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      Whether you agree with them or not, split tickets are a part of UK rail retail. They offer customers a
                      discounted means to travel that can encourage people who might otherwise use other transport to take a train.
                    </div>
                    <div style={{ marginBottom: "20px" }}>

                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/split-tickets/man-with-tickets.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Changing seats
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      It is often the case that different seats are allocated for each ticket. This means that passengers need to change
                      seats mid-journey. This is obviously inconvenient for the passenger who would prefer to remain in the same seat.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Vestibules become crowded with alighting passengers as trains approach stations. Passengers changing seats often have to
                      push their way past these people to get to the next carriage.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Moving against the normal flow can cause friction, accidents and injuries, especially when carrying heavy luggage.
                      Trains are routinely delayed as a result and in some instances, train operators have been fined as a result
                      of those delays.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/split-tickets/changing-seats.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Through-seats
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      Ideally we want our passenger to be allocated the same seat for their entire journey. However, RARS does not
                      support this. Some retailers use basic workarounds to try to find a through-seat, but these are unreliable.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Smartix takes a rather higher-tech approach to the problem and is able to locate all possible through seats and can
                      allocate the most appropriate. Additionally, it can provide a choice of through seats for your customer to choose from.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/split-tickets/through-seats.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Changing seats
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      The common misconception is that providing a means to allocate through-seats for split tickets customers will
                      resolve the problem caused by passenger changing seats. It will do so only where through-seats are available;
                      often there are no through seats available. We need to take another look at the problem...
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      The problem is not that customer move between seats, it is that they do so against the normal flow. A change of seats
                      within the same carriage is unlikely to cause a problem, whereas a move between carriages is likely to, especially if the pasenger 
                      needs to between several carriages.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Again, Smartix offers a solution. Smartix is able to calculate every possible combinations a passenger could be
                      allocated for their journey. It then determines how far the customer needs to move and offers the best combination.
                    </div>

                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/split-tickets/calm-seated-woman.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;