import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import DefaultSplit from '../components/sections/DefaultSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit-SplitTickets';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class SplitTickets extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {
  
    return (
      <React.Fragment>
        <DefaultSplit hasBgColor invertColor title="Intelligent reserved seating for Split Tickets"/>
        <FeaturesSplit invertMobile imageFill topDivider />
        <GenericSection topDivider>
          <div className="container-xs">
          <SectionHeader title="Frequently asked questions" className="center-content" />
            <Accordion>
              <AccordionItem title="Does Smartix make reservations?" active>
                No. Smartix provides you with seat selection data. The actual reservation of seats remains in your control.
              </AccordionItem>
              <AccordionItem title="Can Smartix guarantee to find through seats?">
                Unfortunately Smartix is constrained by the availability of seats on each train and how those seats are configured by the train
                operator. However, we can guarantee that if through seats are possible on a train, Smartix will identify them. 
              </AccordionItem>
              <AccordionItem title="What percentage of reservations get through seats?">
                The likelihood of finding through-seats decreases as the departure date approaches and more seats are already reserved. Overall, 
                Smartix typically finds through seats in over 98% of cases.
              </AccordionItem>
              <AccordionItem title="Does Smartix offer a choice of through seats?">
                Absolutely! Smartix will provide a list of all possible through seats. You can use this list to populate a seat selector to 
                allow your customer to make a choice. 
              </AccordionItem>
              <AccordionItem title="Do seat preferences apply when requesting through seats?">
                Seat preferences are taken into account when raking possible through seats. However, seat preferences are secondary to 
                ensuring through seats are located. So, for example, if the customer has requested a table seat, but the only  
                table seats are available for only part of the journey, Smartix will ignore the table request.
              </AccordionItem>
              <AccordionItem title="What if through seats are not available?">
                <p>Smartix will determine every possible combination of seats that are available for each portion of the customers journey. Smartix will 
                locate the combinations with the minimum movement. It will do this is three ways:
                  <ul>
                    <il>Firstly is will locate seats that can accommodate more that one of the customer consecutive tickets.</il>
                    <li>Secondly it will determine how far it is between seats and rank combinations based on this distance.</li>
                    <li>Finally it will consider exits. Since exits are the choke points, we prioritise moves where the customer can get betwen 
                    their seats without passing an exit.</li>
                  </ul>
                </p>
                <p>A range of possible combinations are returned, ordered by movement score. A simple traffic light attribute indicates the 
                desirability of the seat combination: Green - a good combination with a short move within the same carriage. Amber : A longer move,
                possible to an adjoining carriage. Red - A long move crossing several carriage, or requiring the passenger to exit the train and rejoin
                a separate section.</p>
              </AccordionItem>
              <AccordionItem title="What should we do if there are no green seat combinations?">
                <p>How you choose to retail is always up to you. However, our advice would always be to alert passengers to the need to change seats.
                Amber combinations should be clearly indicated as requiring a longer movement betwene seats. Some customers might find this difficult
                and it may be better ot offer them a different product where a seat change may be avoided.</p>

                <p>In the case of red combinations, the general rule should be to not sell the split and to offer a different product. You should
                explain to the customer that the split requires a very difficult move between seats.</p>
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>         

        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default SplitTickets;