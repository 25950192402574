import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import DefaultSplit from '../components/sections/DefaultSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit-SeatData';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';


class SeatData extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <DefaultSplit hasBgColor invertColor title="Coach & Seat Data"/>
        <FeaturesSplit invertMobile imageFill topDivider />

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader title="Frequently asked questions" className="center-content" />
            <Accordion>
            <AccordionItem title="Does Smartix data include coach images?" active>
                <p>No. Smartix provides all the data necessary for you to render your own images in whatever style you choose.</p>
                <p>SVG is comminly used to create images from the supplied data.</p>
              </AccordionItem>

              <AccordionItem title="Are TOC errors corrected in the Smartix data?">
                <p>RARS data contains a signficant number of coaches / seats where the TOC has provided an incorrect seat position 
                and/or incorrectly applied seat properties. All of this is corrected in the Smaertix data.</p>
              </AccordionItem>

              <AccordionItem title="Does Smartix data includes seats that TOCs have not configured in RARS?">
                <p>Smartix adds data for any issing seats. However, whilst you can display these for completeness, since they are not
                configured in RARS, they will never be selectable.</p>

                <p>Large gaps caused by missing seats cause rendered coach maps to appear incomplete. Hence providing data for these missing
                seats allows your maps to be more visually appealing. For customers using screen readers it avoids large area in your
                seat selector that appear "invisible".</p>
              </AccordionItem>

              <AccordionItem title="What additional data does Smartix provide?">
                <p>Smartix inserts any missing data that should have been provided by the TOC. Generally the Airline attribute is missing. Often
                basic properties such as Aisle/Window are missing (or incorrectly applied). Near to (toilet, luggage, food) attributes are rarely 
                populated. Aisle side is almost never provided by TOCs. Smartix adds all of this data.</p>
                <p>Additionally, Smartix adds some new properties such as near to exits. </p>
              </AccordionItem>

              <AccordionItem title="What data does Smartix provide other than seats?">
                <p>Smartix includes a range of additional position data for:</p>
                <ul>
                  <li>Exits</li>
                  <li>Luggage racks</li>
                  <li>Food kiosks</li>
                  <li>Bike Racks</li>
                  <li>Toilets & Baby Change</li>
                </ul>
                <p>Positional data enables you to generate clickable regions for these items. That is essential when your
                seat selector is being used by a visually impaired customer using a screen reader.</p>
                <p>In addition, Smartix indicates whether it is possible to walk through to coaches on either side.</p>
                <p>WiFi and Quiet carriage are provided as attributes of the coach, not individual seats.</p>
              </AccordionItem>

              <AccordionItem title="Does Smartix provide raw coach/seat data?">
                <p>Smartix does not provide a raw data feed. To use a raw data feed appropriately would require you to make additional RARS calls to 
                determine which seats are available.</p>
                
                <p>To use the feed responsibly, you'd also need to implement an equivalent to Smartix logic for 
                determining which availble seats you should and should not offer to each customer. This would be significant amount of work.</p>

                <p>Hence Smartix provides functions which return all seats that you should offer to your customer based on their particular
                ticket and specified seating requirements.</p>
              </AccordionItem>

              <AccordionItem title="Does Smartix data includes all coaches?">
                <p>The short answer is "no".</p>
                <p>However, Smartix does include all the the coaches that you'd ever want tyo present to your customers in a seat selector.</p>
                <p>RARS includes a number of "ghost" coaches (Fake coaches included in the data simply to support counted places). These
                  are excluded from the Smartix data.</p>
              </AccordionItem>

              <AccordionItem title="What is 'Nearest to'?">
                <p>In RARS, properties such as "near to luggage rack" are applied manually by the TOC to spcific seats. If none of the marked
                seat are available, then a RARS request for a seat "near to luggage" will fail to find a seat and jist give you a random seat.</p>
                <p>Smartix has implemented "nearest to". This does not rely on manual markup of seat properties. Instead Smartix locates the 
                nearest seat on the train to any luggage rack. Thus a similar request to Smartix will always return a seat as near as possible to
                a luggage rack (or toilet, baby change, food kiosk, or exit).</p>
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>         

        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default SeatData;