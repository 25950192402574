import React from 'react';
import DefaultSplit from '../components/sections/DefaultSplit';
import ContactForm from '../components/sections/ContactForm';

class ContactUs extends React.Component {
  render() {
    return (
      <>
        <DefaultSplit hasBgColor invertColor title="Be part of the innovation"/>
        <ContactForm className="illustration-section-01" />
      </>
    );
  }
}

export default ContactUs;