import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import DefaultSplit from '../components/sections/DefaultSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit-WalletTickets';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class WalletTickets extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <DefaultSplit hasBgColor invertColor title="Better engagement with dynamic wallet tickets" />
        <FeaturesSplit invertMobile imageFill topDivider />

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader title="Frequently asked questions" className="center-content" />
            <Accordion>
              <AccordionItem title="Does Smartix write SDCI+ records?" active>
                No. Smartix only generates dynamic wallet tickets (Apple and Google). All other aspects of ticket issue remain under your control.
              </AccordionItem>
              <AccordionItem title="How are tickets updated?">
                When you generate a ticket, you are provided with a reference for that ticket. To send an update you call our API providing that
                reference plus the new ticket details.
              </AccordionItem>
              <AccordionItem title="How do I send a notification?">
                When you generate a ticket, you are provided with a reference for that ticket. To send a notification you call our API providing that
                reference plus the notificsstion text.
              </AccordionItem>
              <AccordionItem title="Which ticket typically require activation?">
                Carnet tickets (such as flexi-seasons) where the customer pre-purchases several tickets as a bundle, each of which must be activated
                prior to travel.
              </AccordionItem>
              <AccordionItem title="What is the process for activating tickets?">
                <p>Ticket activations can be initiated from within the wallet, an App, a website or other means. That notification should instruct your
                  system to do whatever internal processing you need to make the ticket active. You then call our API to push an update to the ticket to
                  put it into an activate state.</p>
                <p>You can also deactivate a ticket, either by sending a new request to our API, or by prioviding an expiry date/time when you do the
                  activation.</p>
              </AccordionItem>
              <AccordionItem title="What ticket details can I change?">
                You can change all details of a ticket except for the unique ticket reference.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>

        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}


export default WalletTickets;