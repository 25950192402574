import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader title="Coach & Seat Data" className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    High quality data
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      Smartix provides coach and seat data that is far superior to that provided by RARS. Data that can
                      be used to present high-quality, consistent and reliable visual seat selection to your customers.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Missing seats are added, mis-aligned seats are positioned appropriately. Seat properties are corrected and missing
                      properties are added.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Position data for all non-seat items (toilets, luggage racks, bike racks baby change, food kiosks and exits) are added,
                      as is data indicating if the coach is connected to coaches on either side, whether or not the coach has WiFi or is a
                      "quiet" coach.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/seat-data/database.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item" id="seat-preferences">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Enhanced seat preferences
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      With additional seat properties included in the Smartix data, your customers are provided with a wider range of seating options.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Customers with mobility limitations are catered for with options such as "near the exit", "near the toilet", as well as "aisle side".
                      Customers travelling with a luggage can choose seats near to luggage racks and those with bike bike can choose seats near to the bike store.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Where RARS "near to" only selects from seats the TOC has marked as "near", Smartix "nearest to" uses spatial searching to locate the
                      nearest available seat regardless of properties applied by the TOC.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/seat-data/seat-preferences.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Powerful seat allocation
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      Smartix offers intelligent preferences based on asking the customer a few basic questions. For example,
                      a customer who says they have mobility issues will probably benefit from having a seat near to an exit and
                      toilet in order to minimise how far they need to walk on the train.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      When selecting seats, Smartix takes account of "high value" seats (such as a seat near the baby change) to ensure
                      they are not allocated unless needed. This ensures that your next customer who might need such as seat is able to
                      secure one.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/seat-data/seat-allocation.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item" id="seat-selection">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Mindful Seat Selection
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      It is easy to think that seat selection just means offering the customer a choice of all possible seats. However, this will
                      have very significnt negative impact on the next passenger's ability to reserve a suitable seat.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Smartix applies the same logic when providing a seat selector data as it does when allocating seats from customer preferences.
                      High value seats are offered only when the customer indicates a need for those seats. Customer travelling alone are not offered
                      seats in groups. If they were then very quickly every set of seats suitable for a groups would be filled with single passengers
                      and we'd be unable to offer a group seats together.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Smartix provides every customer with choice, but provides a guiding mind in the background that directs them towards good choices
                      and away from bad ones. This way, more passengers can be suitably accommodated on each train.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/seat-data/seat-selection.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;