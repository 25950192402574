import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3'])
}

const defaultProps = {
  children: null,
  tag: 'h2'
}

class SectionHeader extends React.Component {

  render() {

    const {
      className,
      title,
      children,
      tag,
      ...props
    } = this.props;

    const classes = classNames(
      'section-header',
      className
    );    

    const Component = tag;

    return (
      <React.Fragment>
        {(title || children) &&
          <div
            {...props}
            className={classes}
          >
            <div className="container">
              {title &&
                <Component className={
                  classNames(
                    'mt-0',
                    children ? 'mb-16' : 'mb-0'
                  )}>{title}</Component>
              }
              <span className="m-0">{children}</span>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;