import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader title="Dynamic tickets react to itinerary changes" className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Dynamic wallet tickets
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      Apple and Google wallet tickets are convienient and offer many benefits over older PDF tickets. Customers
                      enjoy having tickets from all providers in a single place. They also enjoy the features that automatically
                      come with wallet tickets.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Many retailers already offer static wallet tickets, but the complexities of dynamic tickets have so far been shunned
                      by retailers. Smartix API allows you to generate and update dynamic wallet tickets. Smartix takes care of device
                      installation tracking and pushing updates to those devices.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/wallet-tickets/morphing-ticket.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Itinerary changes
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      A customer may choose to change their departure date/time, or a train operator may change their train's
                      itinerary after a customer has purchased their tickets.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      The customer must be informed of the new journey details. Sending new information via email is not guaranteed
                      to be seen by your customer.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      A direct update of the customer's ticket ensures that they have the latest journey data when they travel. An alert
                      also brings the update to the attention of the customer.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/wallet-tickets/seat-change.jpg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Notifications
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      Smartix also allows you to send notifications to the customer mid-journey.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Assist your customers by giving them live platform announcements. This can save your customers valuable time,
                      especially when changing trains en route.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Information about expected delays and eligibility for refunds can further assist your customer.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      There is unlimited scope for live notifications.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/wallet-tickets/smart-watch-update.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Activating tickets
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    <div style={{ marginBottom: "20px" }}>
                      Carnet tickets must be activated prior to travel. Typically your customer would need to use an App and generate a new ticket
                      which they download and install. This does not work well with the concept of wallet tickets. 
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Smartix dyanmic tickets can be activated / deactivated without the need to download additional tickets.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      Smartix dynamic wallet tickets allow your customers to activate their tickets from within their own wallet. No App or
                      external website is needed.
                    </div>
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('../../assets/img/wallet-tickets/activate-ticket.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;