import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class History extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'    );


    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader title="Our History of Innovations" className="center-content">
              <p>The Smartix team has been at the forefront of customer focused innovations since 2006.</p>
              <p>Many of our groundbreaking innovations have been copied and are now the defacto standard for all retailers.</p>

              <p><span className="coloured_bold">Partner with Smartix and benefit from our next innovation!</span></p>
            </SectionHeader>

            <Timeline>
              <TimelineItem date="2005" title="First single handed TIS">
                <main>
                  <p>We were the first (only?) company to build a working rail Ticket Issue System (TIS) with a single developer.</p>
                  <p><span className="coloured_bold">We have always delivered more than the average developer.</span></p>
                </main>

                <modal>
                  <p>TIS were massive undertakings where large teams worked together to produce £Million products.</p>
                  <p>We showed that with an off-the-shelf journey planner and a highly skilled developer the job usually done
                    by large teams over several years can be done single handedly in under a year.</p>
                  <p>Whilst a few TIS have now been built with very small teams, the feat of a single developer doing so has only
                    been replicated once - by us!</p>
                </modal>
              </TimelineItem>


              <TimelineItem date="2006" title="Straight to prices">
                <main>
                  <p>Our first, very tiny, innovation was to bypass the old mechanism of searching separately for
                    journeys, then for prices, by giving the customer the ticket prices at the start.</p>
                  <p><span className="coloured_bold">This was a tiny innovation that was quickly and universally copied by retailers.</span></p>
                </main>

                <modal>
                  <p>The two search mechanism was apparently mandated by RDG and rigorously followed by all with noone thinking
                    to challenge the reasoning.</p>
                  <p>Never being the sort to follow others lead, we did challenge the rules and despite initial push back from RDG
                    we finally deployed our improved search. feedback was universally positive. It was not long before every retailer
                    had copied our lead.</p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2007" title="8 x 8 journeys">
                <main>
                  <p>We were the first to challenge the 4x4 journey dogma that every retailer followed.</p>
                  <p><span className="coloured_bold">Sales increased as a result.</span></p>
                </main>

                <modal>
                  <p>It had always been clear that customers rarely search for their journey at various times of the day.
                    They search for the time they prefer to depart and look at the prices offered. Many customers are not
                    even aware that cheaper fares can be found at other times of the day!</p>

                  <p>Our analysis showed eight journeys was the optimum number to show to ensure that at least one
                    departure time would have cheaper off-peak fares.</p>

                  <p>Whilst the initial thought was that overal income would be reduced due to customer finding cheaper tickets,
                    the actual result was an increase in income due to customer purchasing off-peak tickets who otherwise
                    woudl have abandoned their purchasse having only seen the peak prices.</p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2010" title="Microservices">
                <main>
                  <p>We built a microservice based TIS 4 years before the term microservices was coined!</p>
                  <p><span className="coloured_bold">The result was reduced running costs, improved stability, easier
                    maintenance and easier scaling.</span></p>
                </main>

                <modal>
                  <p>We were designing a new Ticket Issue System for Raileasy and looking at the various difficulties of maintaining and
                    scaling such complex, interconnected software systems.</p>

                  <p>We decided that it would be better to build our TIS as a collection of small, independent functions. </p>

                  <p><span className="coloured_bold">We didn't realise at the time how ground breaking this approach to software engineering was.
                    However, it was 2014 before Martin Fowler at Thoughtworks coined the term "microservices" to describe the concept as a better way to
                    build robust and scaleable systems.</span></p>
                </modal>
              </TimelineItem>


              <TimelineItem date="2010" title="Shopping basket">
                <main>
                  <p>Our new TIS was the first to allow customers to purchase tickets for more than one journey at a time.</p>

                  <p><span className="coloured_bold">We also were the first to allow a customer to buy a ticket without having to
                    register for an account first...</span></p>
                </main>

                <modal>
                  <p>It is staggering to look back at how basic rail ticket retail sites where before us. Seemingly no one at any
                    of the big TIS operators ever considered that customers might prefer to buy several tickets at once!</p>

                  <p>Custmers purchase more than one ticket around 15% of the time today. With several million sales made each day
                    it is easy to comprehend just how many customers were being inconvenienced by being forced to purchase each
                    ticket separately, entering their personal details and credit card details multiple times.</p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2011" title="The rail ticket API is born!">
                <main>
                  <p>We built the very first rail ticket retail API, opening the door for hundreds of independent retailers to
                    integrate rail ticket sales with other products.</p>

                  <p><span className="coloured_bold">The API idea has been copied now of course, but, as is usually the case
                    the copy is a poor substitute for the original.</span></p>
                </main>

                <modal>
                  <p>Before 2011, you either operated a full TIS or you hosted a white-label. There were no options for integrating
                    rail ticket sales into another workflow. Potential affiliates were unable to sell train tickets in a single
                    transaction with other products.</p>

                  <p>So we built the first UK rail retail API for Raileasy. </p>

                  <p>Affiliates were quick to adopt the API, building a wide selection of specialist sites catering to various
                    different customer groups. Other retailrs saw the sucess this brought to Raileasy. Soon everyone was embarking
                    on their own API projects. Now every large TIS operator offers an API. Although none as intuitive or powerful
                    as our own.</p>

                  <p><span className="coloured_bold">API powered affiliates now account for a substantial portion of ticket sales
                    across all retailers.</span></p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2017" title="Split tickets">
                <main>
                  <p>Building upon our shopping basket functionality, our new TIS was the first to implement the sale of split tickets by
                    allowing any number of tickets to form a part of a single journey.</p>
                </main>

                <modal>
                  <p>Split Tickets were a brand new phenomenon. We'd been looking at the possibiltiy for sometime and explored the possibility to
                    allow our customers to purchase multiple tickets for a single journey. With the development of the first Split Ticket journey planner
                    (Fast JP), we pushed ahead with deploying support so that a specialist website for retailing split tickets could be built,
                    powered by our API.</p>

                  <p><span className="coloured_bold">The real accolades go to the Fast JP for bringing Split Tickets out of the specialist forums
                    and making them accessible to the public. We just provided the mean to issue the tickets.</span></p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2019" title="Seat preferences (NRS)">
                <main>
                  <p>Our new Smartix reservation system broke new ground by offering the possibility for customers to
                    specify a wider range of seating preferences. Industry data was corrected an augmented to allow us to
                    offer new preferences such as "near an exit", "near the toilet" - invaluable for mobility impaired
                    passengers.</p>

                  <p><span className="coloured_bold">We also validate options actually avaible on each train, so every 
                  option we offer can be fulfilled.</span></p>
                </main>

                <modal>
                  <p>Forward or Backward facing, Window or Aisle, Table or Airline, Quiet Carriage, WiFi.</p>

                  <p>Almost every retailer offers the same set of options to every customer regardless of whether
                    they can be fulfilled or not. In most cases they are not fulfilled and customers, quit rightly feel, that
                    the preferences are pointless.</p>

                  <p>Customers with mobility issues that might like a seat near the exit, or toilet are not catered for at all.
                    Neither were customers with bikes who might like to sit near to where their bike is stored.</p>

                  <p>Smartix has overhauled seat preferences and surpassed the industry standard that has languished, inadequate
                    for well over a decade.</p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2020" title="Visual seat selection (NRS)" >
                <main>
                  <p>In 2020 we launched the Smartix service. This was a state-of-the-art rail seat reservation and ticket
                    fulfilment system. Smartix contained a host of ground breaking firsts. Our new seat preference data
                    enabled us to offer the first visual seat selection.</p>

                  <p><span className="coloured_bold">As always, our inovation was watched by others. A couple of train
                    companies managed to something similar - but only for their own trains.</span></p>
                </main>

                <modal>
                  <p>The NRS always provided SVG images, but they were frequently faulty and inaccurate and in
                    many cases, simply missing. Our new seat data source was complete and accurate and the for the
                    first time was suitable for displaying train seat maps that custmers could make their selection from.</p>

                  <p>As is usually the case with UK rail, there were added complications. Firstly there was no means to
                    determine which seats were available - only those not already reserved. There wa sno means to differentiate
                    between an available seat and a seat that was not reservable (or not reservable with the customers ticket).</p>

                  <p>So we built a huge cache and slowly mapped every seat in every coach in every train. We determined
                    which seats were reservable and which were not. We determined which seats could be reserved with which types
                    of tickets. Eventually we had all the data to offer reliable seat selection.</p>

                  <p>For the first time, train passengers, like airline passengers are able to see the seat layout and choose specific seats.</p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2020" title="Combined tickets in one PDF">
                <main>
                  <p>Combining multiple tickets into a single a single PDF was so obvious we
                    have to wonder why no other retailer was doing this?</p>

                  <p><span className="coloured_bold">A massive boon, especially for Split Ticket customers.</span></p>
                </main>

                <modal>
                  <p>As with everything in rail retailing, there is an official spcification that says how you must do things.
                    They are usually ill thought out, lacking in detail and difficult to understand. The result it that most
                    develpers just look at what everyone else has done and copies it. Innovation is rare...</p>

                  <p>The spec for PDF tickets meant you had one PDF per ticket. It was bad enough locating the required
                    PDF when you had separate outbound and return tickets. Bit for split tickets you might have to hunt through
                    half a dozen tickets or more.</p>

                  <p><span className="coloured_bold">So, never wanting to follow the herd we put all the tickets (sorted into the order they woudl be needed)
                    into a single PDF. Simple, but so much better for our customers!</span></p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2021" title="Re-issue ToD ticket">
                <main>
                  <p>When a Ticket Machine fails to issue tickets to your customer it usually
                    results in customer having to buy new tickets. Our customers have an automated way to get their tickets reissued!</p>

                  <p><span className="coloured_bold">TVMs failing to print tickets cause problems for many customers.
                    Ticket office staff are rarely prepared to help.</span></p>
                </main>

                <modal>
                  <p>What happens when a Ticket Machine fails to issue tickets to your customer?</p>

                  <p>In most cases they struggle with unhelpful ticket office staff who are reluctant to
                    investigate. Most Ticket Machines fail to update the ticket record to indicate a
                    failure and either leave the ticket "locked" or shown as printed.</p>

                  <p>Customers end up desperately trying to contact their retailer to get new tickets
                    issued but retailers have no means to do so. Usually, new tickets are purchased and
                    the old one refunded. A big problem when the new tickets are more expensive!</p>

                  <p><span className="coloured_bold">Our automated ToD re-issue function fully automates
                    the process of transferring reservations and cheap ticket quota from the old ticket to
                    a new one (so the price remains the same). The old ticket is voided and a new one issued.
                    The customer gets a new collection reference that they can use at a different machine or
                    the ticket office.</span></p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2021" title="Flexi-season wallet tickets">
                <main>
                  <p>Smartix introduced the first updatable wallet tickets to support
                    Flexi-seasons without forcing customers to use an App instead of
                    their phone wallet.</p>

                  <p><span className="coloured_bold">Pre-purchased flexi-season
                    tickets can be activated from within Apple/Google wallet.</span></p>
                </main>

                <modal>
                  <p>As a result of Covid, the season ticket market dropped away. Flexi-Seasons were introduced
                    to allow customers to buy ticket carnets and activate them as needed.</p>

                  <p>Customers were forced by other retailers to use an App (rather than their usual Wallet tickets), or
                    a website to activate tickets then downloading a new one for each journey.</p>

                  <p>Whilst other retailers embarked on months-long, complex developments to support
                    these new Flex-Season tickets in dedicated Apps, Smartix wrapped up the whole thing
                    in a few weeks by utilising the powerful features of Wallet tickets.</p>

                  <p>By using wallet tickets, Smartix was able to maintain all the convenience that
                    customers enjoyed with their other tickets - surfacing, a single wallet for all types
                    of tickets.</p>

                  <p>Customers purchase and install their ticket. When they are ready to travel they
                    click a link withing the ticket itself, which activates the ticket. Smartix then
                    pushes an update direct to the phone changing the visual appearance of the ticket
                    which now displays a valid barcode.</p>

                  <p>Upon expiry of the days travel, another automated update takes place to disable
                    the ticket and return it back to its placeholder state ready to be activated again.</p>

                  <p>Information displayed on the ticket shows the customer how many more days travel
                    they have left on the Flexi-Season ticket.</p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2022" title="Push updates for wallet tickets">
                <main>
                  <p>Smartix is the only system that offers push updates for Wallet tickets. Building upon
                    our Flexi-Season ticket work, we expanded the ability to update Wallet tickets for all
                    other types of tickets.</p>

                  <p><span className="coloured_bold">Instant updates on your phone are infinitely
                    preferable to email notifications.</span></p>
                </main>

                <modal>
                  <p>Wallet tickets are great! Very convienient and very powerful.</p>

                  <p>Sadly retailers have failed to utilise the most powerful feature - push updates.</p>

                  <p>Smartix is different, it pushes the change direct to your phone, so your ticket
                    always has the latest information. Alerts always bring these changes to your attention.</p>

                  <p>If a customer changes reservations or departure date/time then their new
                    itinerary can be pushed automatically to their phone to update their existing ticket.
                    The system is ready for the forthcoming RARS notifications where itinerary and/or seat
                    changes are instrigated by the train company.</p>
                </modal>
              </TimelineItem>


              <TimelineItem date="2024" title="Usable seat data (RARS)">
                <main>
                  <p>We've built a brand new system to extract the raw data from RARS, where, as before, we
                    apply a set of corrections before augmenting the data with additional useful properties
                    to provide high quality coach / seat data for all train services.</p>

                  <p><span className="coloured_bold">Hence, Smartix retains the ability to power accurate
                    and consistent seat maps regardless of the quality of the data supplied by each individual TOC.</span></p>
                </main>

                <modal>
                  <p>RARS promised many improvements (for TOCs) but has delivered little for customers or
                    retailers.</p>

                  <p>We personally have been requesting improved seat data from RDG/TOCs since 2010. We had
                    fully expected that RARS woudl deliver this. It did not...</p>

                  <p>Whilst other retailers and TIS companies continue to complain to RDG about the poor data,
                    Smartix team fully understand the situation. RDG cannot provide a working solution until
                    someone other than the TOCs control the data (Which will soon be GBR). The problem is, that's
                    four years away before work can commence!</p>

                  <p>Not willing to wait for someone else to step up to the mark, Smartix leads the field yet
                    again by providing a full coach and seat data set.</p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2024" title="Mindful visual seat selection">
                <main>
                  <p>Smartix offers the first ever customer seat selection tool where the need
                    to protect the overall carrying capacity is also taken into account. </p>

                  <p><span className="coloured_bold">Again, this desperately needed and unique approach to
                    seat selection is an industry first.</span></p>
                </main>

                <modal>
                  <p>There is a danger with providing the raw seat data that Smartix has. If it is used to
                    offer all customers a choice of all possible seats, then customer will inevitably
                    make choices that reduce the overall carrying capacity of a train.</p>

                  <p>Given free choice, a customer will never choose a seat next to one that is
                    already reserved. This would mean that very quickly, every pair of seats would have
                    one passenger. So when a couple try to make a reservation, they find that they have to sit
                    separately.</p>

                  <p>It is important to ensure that a number of seats are kept in reserve for customers
                    travelling on longer journeys. If every seat is already reserved for a small part
                    of the overall train journey, then it becomes impossible to accommodate a customer
                    travelling a longer distance.</p>

                  <p>There are other reasons to limit access to specific seats in order to better accommodate
                    a larger number of passengers.</p>

                  <p>So, Smartix developed a system that applies custom rules to determine which seats
                    are offered to which customers based on theor specific tickets, needs and preferences.
                    The result is a mindful system that offers customers the
                    choice they want whilst ensuring the needs to the TOC are met by ensuring carrying capacity
                    is not affected.</p>
                </modal>
              </TimelineItem>

              <TimelineItem date="2024" title="Through seats for split tickets">
                <main>
                  <p>Smartix offers the first ever system to allocate through-seats to split ticket
                  customers. It also provides a solution to the real problem that is universally overlooked - 
                  what to do when through-seats are simply unavailable.</p>
                  <p><span className="coloured_bold">Smartix offers the only solution to the real problem.</span></p>
                </main>

                <modal>
                  <p>It seems that being able to allocate through-seats for split tickets customers
                    is the most requested feature from retailers. TOCs see customers moving seats as the
                    biggest problem.</p>

                  <p>Smartix, through implementing the first ever system that allocates through-seats wherever
                    they are possible, demonstrated that the real problem is how to deal with the situation
                    where through-seats are simply not available.</p>

                  <p>In instances where there are no through-seats that we can offer to a customer,
                    we have two choices: allow the customer to move, or don't sell the ticket.</p>

                  <p>The trick is determining which is appropriate.</p>

                  <p>Smartix calculates every possible combination of seats availble to a customer
                    in order to determine which set will result in the passenger moving the shortest
                    distance between seats.</p>

                  <p>If the move is small then there is little inconvienence to the customer, other
                    passengers or the TOC. If it is large then there is likely to be and the retailer
                    can be directed to offer the customer an alternative product.</p>
                </modal>
              </TimelineItem>

            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

History.propTypes = propTypes;
History.defaultProps = defaultProps;

export default History;