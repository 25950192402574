import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}


class ContactForm extends React.Component {

  constructor() {
    super()
    this.state = {
      mouseActivated : false
    }
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Welcome back. We exist to make entrepreneurship easier.',
    };

    const handleMouseOver = () => this.setState({mouseActivated:true})

    const coded1 = "aWFuLmJhbGVAc21hcnRpeC51aw=="
    const coded2 = "KzQ0IDc5NjcgMDMxNDMw"

    const decode64 = coded => this.state.mouseActivated ? atob(coded) : "<mouse over to reveal>"

    const openEmail = () => {
      document.location.href = "mailto:" + decode64(coded1)
    }

    const openPhone= () => {
      document.location.href = "tel:" + decode64(coded2)
    }

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">

                  <div className="mb-12">
                    <b>Smartix UK</b><br/>
                    93 Guillemot Road<br />
                    Portishead<br />
                    Bristol<br />
                    BS20 7PG
                  </div>

                  <div className="mt-24 mb-32">
                    <Button color="primary" wide onClick={openEmail} onMouseOver={handleMouseOver}>Email : {decode64(coded1)}</Button>
                  </div>

                  <div className="mt-24 mb-32">
                    <Button color="primary" wide onClick={openPhone}>Phone : {decode64(coded2)}</Button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;